import * as React from "react"
import { graphql } from "gatsby"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"
import { linkResolver } from "../utils/link-resolver"
import Layout from "../components/layout"
import { Seo } from "../components/seo"
import PageContent from "../components/page-content"
import { Helmet } from "react-helmet"
import IframeResizer from 'iframe-resizer-react'
// import SliceZone from "../components/slice-zone"
// import { JsonDebugger } from "../components/json-debugger"

const FeedChart = (data) => {
  // const doc = data.prismicPage

  // React.useEffect(() => {
  //   if (window && window.iFrameResize) {
  //     window.iFrameResize({ log: false }, "#calc-frame")
  //   }
  // }, [window.iFrameResize])

  if (!data) return null

  return (
    <Layout>
      <Seo title={"Feed Chart"} />
      <Helmet>
        <script src="/scripts/iframeResizer.min.js"></script>
      </Helmet>
      {/* <h1>Feed Chart</h1> */}
      <PageContent>
        {/* <iframe */}
        <IframeResizer
          id="calc-frame"
          src="https://calc.dakine420.com/home?lightHeader=true"
          scrolling="no"
          // style={{ overflow: "hidden", height: "3000px", width: "100%" }}
          style={{ width: "1px", minWidth: "100%", height: "3000px" }}
          // onLoad={() => {
          //   iFrameResize({ log: false }, "#calc-frame")
          // }}
          // ></iframe>
        />
      </PageContent>
      {/* <SliceZone sliceZone={doc.data.body} /> */}
      {/* <JsonDebugger json={data} /> */}
    </Layout>
  )
}

// export const query = graphql`
//   query PageQuery($id: String) {
//     prismicPage(id: { eq: $id }) {
//       _previewable
//       data {
//         document_display_name {
//           text
//         }
//         body {
//           ... on PrismicSliceType {
//             slice_type
//           }
//           ...PageDataBodyText
//           ...PageDataBodyQuote
//           ...PageDataBodyFullWidthImage
//           ...PageDataBodyImageGallery
//           ...PageDataBodyImageHighlight
//         }
//       }
//     }
//   }
// `

export default FeedChart
// export default withPrismicPreview(FeedChart, [
//   {
//     repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
//     linkResolver,
//   },
// ])
